import { Routes, Route } from 'react-router-dom';
import './index.css';
import Cricket from './pages/Cricket/Cricket';

//disble cursor in code box

function App() {
  return (
    <Routes>
      <Route path="/" element={<Cricket />} />
    </Routes>
  );
}

export default App;
