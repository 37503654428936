import React from 'react';
import './scroll.css';
const Scroll = () => {
  const content =
    'Get on the Leaderboard and win exciting Merchandise from Straight Drive !';
  return (
    <>
      <div
        style={{ background: '#0c2226', height: '100px', overflowY: 'hidden' }}
        className="scrollEleSpec cricket"
      >
        <div style={{ color: '#f5d65a' }} className="loopEle primary">
          {content}
        </div>
        <div style={{ color: '#f5d65a' }} className="loopEle secondary">
          {content}
        </div>
      </div>
    </>
  );
};
export default Scroll;
