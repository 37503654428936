import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { getPlayers } from './utils';
import Scroll from '../../components/scroll/Scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const CricketTopNav = ({ type }) => {
	return (
		<div className="cricket-top-nav-container">
			<div
				className="--img"
				style={{ display: 'flex', justifyContent: 'start' }}
			>
				<img
					src="cricket.svg"
					className="cricket"
					alt="cricket-logo"
				/>
			</div>
			<span className="--lbd">
				{type === 'cricket' ? 'CRICKET' : 'BASEBALL'} LEADERBOARD TOP SCORES
			</span>
			<div className="--img">
				<img
					src="logo.svg"
					className="logo"
					alt="logo"
				/>
			</div>
		</div>
	);
};

const TableHeader = () => {
	return (
		<div className="cricket-table-header">
			<span className="--rank">RANK</span>
			<span className="--player-name">PLAYER</span>
			<span className="--level-played">LEVEL</span>
			<span className="--balls">BALLS</span>
			<span className="--runs">RUNS</span>
			<span className="--strike-rate">STRIKE/R</span>
		</div>
	);
};

const TableColumns = ({ index, user }) => {
	const getStyles = () => {
		if (index === 0) {
			return {
				border: 'none',
				color: 'black',
				background:
					'linear-gradient(90deg, #B8851B 0%, #D2A73E 8.33%, #D6AB42 17.19%, #F8DD7B 31.25%, #FDED99 48.96%, #FBEA92 66.15%, #F3D773 82.29%, #E2BD56 91.67%, #B58017 100%)',
			};
		} else if (index === 1) {
			return {
				border: 'none',
				color: 'black',
				background:
					'linear-gradient(90deg, #565656 0%, #D2D2D2 0%, #989898 9.67%, #FFFFFF 69.66%, #C0C0C0 82.12%, #757575 100%)',
			};
		} else if (index === 2) {
			return {
				border: 'none',
				background:
					'linear-gradient(90deg, #793515 0%, #893D14 11.5%, #B55E33 26.27%, #A04D27 40.88%, #C17651 55.66%, #993F0F 79.6%, #AC582F 89.16%, #863711 100%)          ',
			};
		} else return {};
	};

	const styles = getStyles();

	return (
		<>
			<div
				style={styles}
				className="cricket-table-column"
			>
				<span className="--rank --index">{index + 1}</span>
				<span className="--column-player-name --player-name">
					{user.playerName}
				</span>
				<span className="--column-level-played --level-played">
					{user.gameLevel}
				</span>
				<span className="--column-balls --balls">{user.totalBall}</span>
				<span className="--column-runs --runs">{user.totalRun}</span>
				<span className="--column-strike-rate --strike-rate">
					{user.strikeRate}
				</span>
			</div>
			{index === 9 ? null : <div className="--line"></div>}
		</>
	);
};

const media = [
	{
		name: 'Display Video.mp4',
		type: 'vid',
	},
	{
		name: 'test.mp4',
		type: 'vid',
	},
	{
		name: 'img2.jpg',
		type: 'img',
	},
];
const Cricket = () => {
	const [players, setPlayers] = useState([
		{
			playerName: 'Player01',
			gameLevel: 'Local',
			totalBall: 12,
			totalRun: 72,
			strikeRate: 600,
			rank: 1,
		},
		{
			playerName: 'Ambrish ',
			gameLevel: 'Local',
			totalBall: 6,
			totalRun: 30,
			strikeRate: 500,
			rank: 2,
		},
		{
			playerName: 'Skanda',
			gameLevel: 'Backyard',
			totalBall: 18,
			totalRun: 90,
			strikeRate: 500,
			rank: 3,
		},
		{
			playerName: 'Aditya ',
			gameLevel: 'Backyard',
			totalBall: 6,
			totalRun: 28,
			strikeRate: 466,
			rank: 4,
		},
		{
			playerName: 'Testing ',
			gameLevel: 'Local',
			totalBall: 18,
			totalRun: 84,
			strikeRate: 466,
			rank: 5,
		},
		{
			playerName: 'testing ',
			gameLevel: 'Local',
			totalBall: 12,
			totalRun: 55,
			strikeRate: 458,
			rank: 6,
		},
		{
			playerName: 'Atharva ',
			gameLevel: 'Backyard',
			totalBall: 18,
			totalRun: 79,
			strikeRate: 438,
			rank: 7,
		},
		{
			playerName: 'Testing ',
			gameLevel: 'Local',
			totalBall: 18,
			totalRun: 79,
			strikeRate: 438,
			rank: 8,
		},
		{
			playerName: 'Ravi',
			gameLevel: 'Local',
			totalBall: 18,
			totalRun: 78,
			strikeRate: 433,
			rank: 9,
		},
		{
			playerName: 'krithik',
			gameLevel: 'Backyard',
			totalBall: 18,
			totalRun: 78,
			strikeRate: 433,
			rank: 10,
		},
	]);
	const cricketVideoRef = useRef(null);
	const cricketTableColumns = useRef(null);
	const [activeSlide, setActiveSlide] = useState(0);
	const [mediaSwiper, setMediaSwiper] = useState(null);
	const [activeMedia, setActiveMedia] = useState(0);
	const mediaRefs = [useRef(), useRef(), useRef()];

	useEffect(() => {
		if (mediaRefs[activeMedia] && media[activeMedia].type === 'vid') {
			mediaRefs[activeMedia].current.play();
		}
		if (media[activeMedia].type === 'img') {
			setTimeout(() => {
				console.log('ended');
				moveHandler();
			}, 5000);
		}
	}, [activeMedia]);

	const moveHandler = () => {
		if (activeMedia === media.length - 1) {
			mediaSwiper.slideTo(0);
		} else {
			mediaSwiper.slideNext();
		}
	};

	const Base = ({ type }) => {
		return (
			<div style={{ height: '100%' }}>
				<CricketTopNav type={type} />
				<div
					className="cricket-table"
					style={{ height: 'calc(100% - 120px)' }}
				>
					<TableHeader />
					<div
						style={{ flexGrow: '1' }}
						ref={cricketTableColumns}
						className="cricket-table-columns"
					>
						{players.map((user, i) => (
							<TableColumns
								key={i}
								index={i}
								user={user}
							/>
						))}
					</div>
				</div>
			</div>
		);
	};

	const acceptGetPlayers = (response) => {
		if (response.status === 200) {
			setPlayers(response.data.responseBody.data);
		} else {
			console.log('error', response);
		}
	};
	const onReject = (error) => {
		console.log('error', error);
	};

	useEffect(() => {
		if (cricketVideoRef && cricketTableColumns) {
			const videoHeight = cricketVideoRef.current.clientHeight;
			cricketTableColumns.current.style.height = `calc(100vh - ${videoHeight}px  - 100px - 85px - 50px)`;
			console.log(cricketTableColumns.current.style.height);
		}
		getPlayers(acceptGetPlayers, onReject);
	}, []);

	useEffect(() => {
		getPlayers(acceptGetPlayers, onReject);
	}, [activeSlide]);

	return (
		<div className="cricket-leader-board-container">
			<div style={{ height: '100%' }}>
				<Swiper
					modules={[Autoplay]}
					style={{ height: '100%' }}
					autoplay={{ delay: 10000 }}
					onSlideChange={(e) => setActiveSlide(e.activeIndex)}
				>
					<SwiperSlide>
						<Base type={'cricket'} />
					</SwiperSlide>
					<SwiperSlide>
						<Base type={'baseball'} />
					</SwiperSlide>
				</Swiper>
			</div>

			<div
				ref={cricketVideoRef}
				className="cricket-video"
			>
				<Swiper
					onInit={(swiper) => setMediaSwiper(swiper)}
					onSlideChange={(swiper) => setActiveMedia(swiper.activeIndex)}
				>
					{media.map((m, i) => {
						if (m.type === 'vid') {
							return (
								<SwiperSlide>
									<video
										src={m.name}
										alt="cricket video"
										ref={mediaRefs[i]}
										onEnded={() => {
											console.log('moving to the next ');
											if (mediaSwiper) {
												moveHandler();
											}
										}}
										controls={false}
										autoPlay={false}
									/>
								</SwiperSlide>
							);
						}
						return (
							<SwiperSlide>
								<img
									src={m.name}
									alt="cricket video"
									ref={mediaRefs[i]}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>

			<Scroll />
		</div>
	);
};

export default Cricket;
