import axios from 'axios';

export const getPlayers = async (onAccept, onReject) => {
  try {
    const response = await axios.get(
      'https://leaderboard.straightdrive.xyz/sd/orbit/cricket/v1/kiosk/KIOSK123',
      {
        headers: {
          Authorization:
            `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    );
    onAccept(response);
  } catch (e) {
    onReject(e);
  }
};
