// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollEleSpec {
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background: white;
  color: black;
  font-size: 2.5vh;
  font-weight: bolder;
  font-family: Montserrat;
}
.loopEle {
  padding: 0 30px;
}
@media screen and (max-width: 800px) {
  .primary {
    animation: textScroll 5s linear infinite;
    position: absolute;
  }
  .secondary {
    animation: textScroll2 5s linear infinite;
    position: absolute;
  }
}
.primary {
  animation: textScroll 5s linear infinite;
  position: absolute;
}
.secondary {
  animation: textScroll2 5s linear infinite;
  position: absolute;
}

@keyframes textScroll {
  from {
    left: 0%;
  }
  to {
    left: -230%;
  }
}
@keyframes textScroll2 {
  from {
    left: 230%;
  }
  to {
    left: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/scroll/scroll.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;AACjB;AACA;EACE;IACE,wCAAwC;IACxC,kBAAkB;EACpB;EACA;IACE,yCAAyC;IACzC,kBAAkB;EACpB;AACF;AACA;EACE,wCAAwC;EACxC,kBAAkB;AACpB;AACA;EACE,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,QAAQ;EACV;AACF","sourcesContent":[".scrollEleSpec {\n  width: 100%;\n  overflow-x: hidden;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  position: relative;\n  background: white;\n  color: black;\n  font-size: 2.5vh;\n  font-weight: bolder;\n  font-family: Montserrat;\n}\n.loopEle {\n  padding: 0 30px;\n}\n@media screen and (max-width: 800px) {\n  .primary {\n    animation: textScroll 5s linear infinite;\n    position: absolute;\n  }\n  .secondary {\n    animation: textScroll2 5s linear infinite;\n    position: absolute;\n  }\n}\n.primary {\n  animation: textScroll 5s linear infinite;\n  position: absolute;\n}\n.secondary {\n  animation: textScroll2 5s linear infinite;\n  position: absolute;\n}\n\n@keyframes textScroll {\n  from {\n    left: 0%;\n  }\n  to {\n    left: -230%;\n  }\n}\n@keyframes textScroll2 {\n  from {\n    left: 230%;\n  }\n  to {\n    left: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
